import React from 'react'

import { Gallery } from '@te-digi/styleguide'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'

export const GalleryExample = () => (
  <Gallery
    images={[...Array(7).keys()].map(i => ({
      label: `Image ${i + 1}`,
      url: `https://picsum.photos/id/${350 + i}/${
        Math.random() > 0.5 ? '1000/750' : '750/1000'
      }`,
      thumbnailUrl: `https://picsum.photos/id/${350 + i}/300/225`
    }))}
  />
)

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Gallery"
    components={[{ component: Gallery }]}
  >
    <Section>
      <Playground>{GalleryExample()}</Playground>
    </Section>
  </Content>
)

export default Page

import React from 'react'

import { Paragraph } from '@te-digi/styleguide'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import placeholder1Image from '../../assets/placeholder-1.jpg'

export const FigureExample = () => (
  <figure className="figure">
    <img
      alt="Sed posuere interdum sem"
      className="img figure-img img-fluid mb-0"
      src={placeholder1Image}
    />
    <figcaption className="figure-caption">
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
    </figcaption>
  </figure>
)

const Page = () => (
  <Content
    heading="Figure"
    subHeading="Verkkopalvelu"
  >
    <Paragraph>Kuva kuvatekstillä.</Paragraph>
    <Playground format="html">
      <FigureExample />
    </Playground>
  </Content>
)

export default Page

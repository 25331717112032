import React, { ComponentProps, ReactNode } from 'react'

import {
  Button,
  ChevronRightIcon,
  GridList,
  Main,
  Wrapper
} from '@te-digi/styleguide'

import { LinkMock } from '../../../components/LinkMock'
import { SkipLinkHTMLExample } from '../../../pages/navigointi/skiplink'

import { FooterHTMLExample } from '../FooterHTMLExample'
import { HeaderUserHTMLExample } from '../HeaderUserHTMLExample'
import { ImageSectionReversedExample } from '../ImageSectionReversedExample'
import IconHTMLExample from '../IconHTMLExample'

import { Testimonials } from './HomeExample'

const LookAllButton = ({
  children = 'Katso kaikki',
  color = 'white',
  variant = 'outline'
}: {
  children?: ReactNode
  color?: ComponentProps<typeof Button>['color']
  variant?: ComponentProps<typeof Button>['variant'] | null
}) => (
  <div className="text-center">
    <Button
      as={LinkMock}
      color={color}
      iconRight={<ChevronRightIcon />}
      variant={variant !== null ? variant : undefined}
    >
      {children}
    </Button>
  </div>
)

const MainPageExample = () => (
  <>
    {SkipLinkHTMLExample()}
    <Wrapper>
      {HeaderUserHTMLExample({
        activePrimaryItem: 'Henkilöasiakkaat',
        activeSecondaryItem: 'Ammattitieto'
      })}
      <Main>
        {ImageSectionReversedExample()}
        <div className="container-fluid">
          <div className="row">
            <div className="offset-sm-1 col-sm-10 offset-lg-0 col-lg-12">
              <div className="mt-layout-md mb-layout-md">
                <GridList
                  gap="xl"
                  md={2}
                  lg={3}
                >
                  {[
                    {
                      icon: (
                        <IconHTMLExample
                          name="QuestionIcon"
                          size="layout-xl"
                        />
                      ),
                      heading: 'Ammatinvalinta ja uraohjaus',
                      body: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed posuere interdum sem. Quisque ligula eros ullamcorper quis, lacinia quis facilisis sed sapien. Mauris varius diam vitae arcu. Sed arcu lectus auctor vitae, consectetuer et venenatis eget velit.'
                    },
                    {
                      icon: (
                        <IconHTMLExample
                          name="BookIcon"
                          size="layout-xl"
                        />
                      ),
                      heading: 'Koulutusneuvonta',
                      body: 'Sed posuere interdum sem. Quisque ligula eros ullamcorper quis, lacinia quis facilisis sed sapien. Mauris varius diam vitae arcu. Sed arcu lectus auctor vitae, consectetuer et venenatis eget velit.'
                    },
                    {
                      icon: (
                        <IconHTMLExample
                          name="CrownIcon"
                          size="layout-xl"
                        />
                      ),
                      heading: 'Uravalmennus',
                      body: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed posuere interdum sem. Quisque ligula eros ullamcorper quis, lacinia quis facilisis sed sapien. Mauris varius diam vitae arcu. Sed arcu lectus auctor vitae.'
                    }
                  ].map(item => (
                    <article
                      className="box border-0 mb-0 radius-lg shadow-card flex flex-column"
                      key={item.heading}
                    >
                      <header className="text-center text-primary">
                        {item.icon}
                        <h4 className="h3">{item.heading}</h4>
                      </header>
                      <p>{item.body}</p>
                      <div className="mt-auto">
                        <LookAllButton
                          color="secondary"
                          variant={null}
                        >
                          Lue lisää
                        </LookAllButton>
                      </div>
                    </article>
                  ))}
                </GridList>
              </div>
            </div>
          </div>
        </div>
        <div className="content-background content-background-gradient-with-decoration">
          {Testimonials()}
        </div>
      </Main>
      <FooterHTMLExample />
    </Wrapper>
  </>
)

export { MainPageExample }

import React from 'react'

import { ArticleExample } from '../examples/html/pages/ArticleExample'
import { BrowseJobsExample } from '../examples/react/pages/BrowseJobsExample'
import { Content } from '../components/Content'
import { ContentPageExample } from '../examples/html/pages/ContentPageExample'
import { FormPageExample } from '../examples/react/pages/FormPageExample'
import { HomeExample } from '../examples/html/pages/HomeExample'
import { ListingExample } from '../examples/react/pages/ListingExample'
import { MainPageExample } from '../examples/html/pages/MainPageExample'
import { MultiPageFormExample } from '../examples/react/pages/MultiPageFormExample'
import { NotFoundExample } from '../examples/html/pages/404Example'
import { Playground } from '../components/Playground'
import { ProfileExample } from '../examples/react/pages/ProfileExample'
import { Section } from '../components/Section'
import { SectionHeading } from '../components/SectionHeading'

const Page = () => (
  <Content
    heading="Mallit"
    lead="Style Guide -komponenteista luotuja esimerkkejä kokonaisuuksista. Mallit eivät välttämättä kuvasta oikeaa toteutusta, vaan ovat enemmänkin referenssejä komponenttien käyttömahdollisuuksista."
  >
    <Section title="Verkkopalvelun mallit">
      <SectionHeading>Etusivu</SectionHeading>
      <Playground
        format="html"
        example={HomeExample}
        fullScreenLink="/mallit/etusivu/"
      />
      <SectionHeading>Pääsivu</SectionHeading>
      <Playground
        format="html"
        example={MainPageExample}
        fullScreenLink="/mallit/paasivu/"
      />
      <SectionHeading>Sisältösivu</SectionHeading>
      <Playground
        example={ContentPageExample}
        format="html"
        fullScreenLink="/mallit/sisaltosivu/"
      />
      <SectionHeading>Artikkeli</SectionHeading>
      <Playground
        example={ArticleExample}
        format="html"
        fullScreenLink="/mallit/artikkeli/"
      />
      <SectionHeading>Sivua ei löydy</SectionHeading>
      <Playground
        example={NotFoundExample}
        format="html"
        fullScreenLink="/mallit/404/"
      />
    </Section>
    <Section title="React-mallit">
      <SectionHeading>Lomake</SectionHeading>
      <Playground
        example={FormPageExample}
        fullScreenLink="/mallit/lomake/"
      />
      <SectionHeading>Monisivuinen lomake</SectionHeading>
      <Playground
        example={MultiPageFormExample}
        fullScreenLink="/mallit/monisivuinen-lomake/"
      />
      <SectionHeading>Työnhakuprofiili</SectionHeading>
      <Playground
        example={ProfileExample}
        fullScreenLink="/mallit/tyonhakuprofiili/"
      />
      <SectionHeading>Työpaikkailmoitukset</SectionHeading>
      <Playground
        example={ListingExample}
        fullScreenLink="/mallit/tyopaikkailmoitukset/"
      />
      <SectionHeading>Selaa työpaikkoja</SectionHeading>
      <Playground
        example={BrowseJobsExample}
        fullScreenLink="/mallit/selaa-tyopaikkoja/"
      />
    </Section>
  </Content>
)

export default Page

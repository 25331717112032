import React, { ComponentProps, useState } from 'react'

import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ButtonGroup,
  Buttons,
  CardViewFillIcon,
  Checkbox,
  CheckboxGroup,
  ChevronDownIcon,
  ChevronUpIcon,
  Col,
  CollapsibleContent,
  Columns,
  Divider,
  FormGroup,
  GridList,
  Heading,
  Inline,
  InlineLabel,
  InlineList,
  InlineListItem,
  Input,
  Link,
  ListViewFillIcon,
  Main,
  Radio,
  RadioGroup,
  Row,
  Select,
  SkipLink,
  Spacer,
  SquareButton,
  Stack,
  Tag,
  TagButton,
  TagDivider,
  useBreakpoint,
  Wrapper,
  XIcon
} from '@te-digi/styleguide'

import { LinkMock } from '../../../components/LinkMock'

import { BoxJobExample } from '../BoxJobExample'
import { HeaderExample } from '../HeaderExamples'
import { PaginationExample1 } from '../PaginationExample1'
import { FooterExample } from '../FooterExample'

const BrowseJobsExample = () => {
  const [view, setView] = useState('list')
  const [showAdvanced, setShowAdvanced] = useState(false)
  const [order, setOrder] = useState<ComponentProps<typeof Select>['value']>({
    label: 'Osuvin',
    value: 'osuvin'
  })
  const doSomething = () => {
    // This is intentional
  }

  return (
    <>
      <SkipLink />
      <Wrapper>
        <HeaderExample activePrimaryItem="Omat sivut" />
        <Columns layout="wide">
          <Main>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link as={LinkMock}>Omat sivut</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>Selaa työpaikkoja</BreadcrumbItem>
            </Breadcrumb>
            <Heading>Selaa työpaikkoja</Heading>
            <Box color="light">
              <Row alignItems="end">
                <Col md={7}>
                  <FormGroup noMargin>
                    <Input
                      label="Sanahaku"
                      subLabel="Esim. tehtävänimike tai työtehtävät"
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <Spacer marginTop={{ xs: 'md', md: 0 }}>
                    <FormGroup noMargin>
                      <Select
                        label="Sijainti"
                        options={[{ label: 'Tampere', value: 'tampere' }]}
                        subLabel="Maakunta, kunta, maa"
                      />
                    </FormGroup>
                  </Spacer>
                </Col>
              </Row>
              <CollapsibleContent isOpen={showAdvanced}>
                <Spacer marginTop="lg">
                  <Row>
                    <Col
                      sm={6}
                      lg={3}
                    >
                      <FormGroup>
                        <CheckboxGroup
                          help="Help"
                          label="Työaika"
                          name="tyoaika"
                          onChange={() => {
                            doSomething()
                          }}
                        >
                          <Checkbox value="kokoaikainen">Kokoaikainen</Checkbox>
                          <Checkbox value="osa-aikainen">Osa-aikainen</Checkbox>
                        </CheckboxGroup>
                      </FormGroup>
                      <FormGroup>
                        <CheckboxGroup
                          help="Help"
                          label="Työn jatkuvuus"
                          name="tyon-jatkuvuus"
                          onChange={() => {
                            doSomething()
                          }}
                        >
                          <Checkbox value="toistaiseksi">
                            Toistaiseksi voimassa oleva
                          </Checkbox>
                          <Checkbox value="maaraaikainen">
                            Määräaikainen
                          </Checkbox>
                        </CheckboxGroup>
                      </FormGroup>
                    </Col>
                    <Col
                      sm={6}
                      lg={3}
                    >
                      <FormGroup>
                        <CheckboxGroup
                          help="Help"
                          label="Palvelusuhde"
                          name="palvelusuhde"
                          onChange={() => {
                            doSomething()
                          }}
                        >
                          {[
                            'Työsuhde',
                            'Virkasuhde',
                            'Vuokratyö',
                            'Työharjoittelu',
                            'Oppisopimus',
                            'Kausityö',
                            'Kesätyö'
                          ].map(item => (
                            <Checkbox
                              key={item}
                              value={item}
                            >
                              {item}
                            </Checkbox>
                          ))}
                        </CheckboxGroup>
                      </FormGroup>
                    </Col>
                    <Col
                      sm={6}
                      lg={3}
                    >
                      <FormGroup>
                        <CheckboxGroup
                          help="Help"
                          label="Työskentelyaika"
                          name="tyoskenteluaika"
                          onChange={() => {
                            doSomething()
                          }}
                        >
                          {[
                            'Päivisin',
                            'Iltaisin',
                            'Yöllä',
                            'Aamulla',
                            'Arkisin',
                            'Viikonloppuisin',
                            'Vuorotyö',
                            'Jaksotyö'
                          ].map(item => (
                            <Checkbox
                              key={item}
                              value={item}
                            >
                              {item}
                            </Checkbox>
                          ))}
                        </CheckboxGroup>
                      </FormGroup>
                    </Col>
                    <Col
                      sm={6}
                      lg={3}
                    >
                      <FormGroup>
                        <RadioGroup
                          help="Help"
                          label="Julkaisu"
                          name="julkaisu"
                          onChange={() => {
                            doSomething()
                          }}
                        >
                          {['Kaikki', '24 tuntia', '3 päivää', 'viikko'].map(
                            item => (
                              <Radio
                                key={item}
                                value={item}
                              >
                                {item}
                              </Radio>
                            )
                          )}
                        </RadioGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                </Spacer>
              </CollapsibleContent>
              <Divider />
              <Spacer marginTop={{ xs: 'md', md: 0 }}>
                <Buttons
                  align="right"
                  noMargin
                >
                  <Button
                    iconRight={
                      showAdvanced ? <ChevronUpIcon /> : <ChevronDownIcon />
                    }
                    onClick={() => setShowAdvanced(!showAdvanced)}
                    variant="plain"
                  >
                    {showAdvanced ? 'Vähemmän' : 'Enemmän'} hakuehtoja
                  </Button>
                  <Button>Näytä työpaikat</Button>
                </Buttons>
              </Spacer>
            </Box>
            <Inline
              alignItems="center"
              gap="xs"
              wrap="wrap"
            >
              <Heading
                level={2}
                size={5}
                noMargin
              >
                Hakurajaukset:
              </Heading>
              {[
                'Kuopio',
                'Tampere',
                'Helsinki',
                'Pirkanmaa',
                'Uusimaa',
                'Kokoaikainen'
              ].map(item => (
                <Tag
                  color="primary"
                  key={item}
                  label={item}
                  size="xs"
                >
                  <TagDivider />
                  <TagButton icon={<XIcon />}>Poista valinta: {item}</TagButton>
                </Tag>
              ))}
              <Spacer marginLeft="sm">
                <Button variant="plain">Tyhjennä kaikki hakurajaukset</Button>
              </Spacer>
            </Inline>
            <Divider />
            <Spacer marginBottom="lg">
              <Row alignItems="center">
                <Col>
                  <Heading
                    level={2}
                    noMargin
                    size={5}
                  >
                    <InlineList>
                      <InlineListItem>1234 työpaikkaa</InlineListItem>
                      <InlineListItem>Sivu 1/24</InlineListItem>
                    </InlineList>
                  </Heading>
                </Col>
                <Col
                  sm={6}
                  md={4}
                >
                  <InlineLabel
                    label="Järjestys"
                    htmlFor="order"
                  >
                    <Select
                      id="order"
                      onChange={value => setOrder(value)}
                      value={order}
                      options={[
                        {
                          label: 'Osuvin',
                          value: 'osuvin'
                        },
                        {
                          label: 'Haku päättyy',
                          value: 'haku-paattyy'
                        }
                      ]}
                    />
                  </InlineLabel>
                </Col>
                {useBreakpoint('md') && (
                  <Col md="auto">
                    <InlineLabel
                      id="view-label"
                      label="Näytä"
                    >
                      <ButtonGroup ariaLabelledby="view-label">
                        <SquareButton
                          ariaPressed={view === 'list' ? 'true' : 'false'}
                          iconLeft={<ListViewFillIcon />}
                          onClick={() => setView('list')}
                          variant={view === 'list' ? undefined : 'outline'}
                        >
                          Lista
                        </SquareButton>
                        <SquareButton
                          ariaPressed={view === 'card' ? 'true' : 'false'}
                          iconLeft={<CardViewFillIcon />}
                          onClick={() => setView('card')}
                          variant={view === 'card' ? undefined : 'outline'}
                        >
                          Kortit
                        </SquareButton>
                      </ButtonGroup>
                    </InlineLabel>
                  </Col>
                )}
              </Row>
            </Spacer>
            {view === 'card' ? (
              <GridList
                sm={2}
                xl={3}
              >
                {[...Array(24).keys()].map(i => (
                  <React.Fragment key={i}>
                    <BoxJobExample />
                  </React.Fragment>
                ))}
              </GridList>
            ) : (
              <Stack
                as="ul"
                gap="md"
              >
                {[...Array(24).keys()].map(i => (
                  <React.Fragment key={i}>
                    <BoxJobExample isWide />
                  </React.Fragment>
                ))}
              </Stack>
            )}
            <Divider />
            <Row>
              <Col
                sm={5}
                md={4}
              >
                <InlineLabel
                  htmlFor="results"
                  label="Näytä tuloksia sivulla"
                >
                  <Select
                    id="results"
                    value={{ label: '15', value: 15 }}
                  />
                </InlineLabel>
              </Col>
            </Row>
            <Spacer marginTop="layout-md">
              <PaginationExample1 />
            </Spacer>
          </Main>
        </Columns>
        <FooterExample />
      </Wrapper>
    </>
  )
}

export { BrowseJobsExample }

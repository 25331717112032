import React, { useState } from 'react'

import {
  AlertCircleIcon,
  Badge,
  Badges,
  Box,
  BoxGroup,
  Col,
  Heading,
  Inline,
  Lead,
  LocationIcon,
  Row,
  Spacer,
  Text,
  useBreakpoint
} from '@te-digi/styleguide'

import { LinkMock } from '../../components/LinkMock'

import { useLorem } from '../../lorem'

const BoxJobExample = ({ isWide }: { isWide?: boolean }) => {
  const showWideCard = useBreakpoint('md') && isWide
  const lorem = useLorem({ sentences: 2 })
  const company = useLorem({ words: 2 })
  const [jobTitle] = useState(
    ['Automyyjä', 'Kokki', 'Myyjä', 'Sairaanhoitaja', 'Palkanlaskija'][
      Math.floor(Math.random() * 5)
    ]
  )
  const [location] = useState(
    ['Tampere', 'Helsinki', 'Kuopio', 'Turku', 'Pori'][
      Math.floor(Math.random() * 5)
    ]
  )
  const [description] = useState(`Haemme lorem ipsum dolor vahvistukseksi
    sit amet toimipisteeseen. Työtehtäviisi kuuluvat posuere interdum ullamcorper sekä varius diam vitae arcu.
    ${Math.random() > 0.5 ? lorem : ''}
    `)

  return (
    <>
      {/* EXAMPLE_START */}
      <BoxGroup
        as={LinkMock}
        to="#"
      >
        {showWideCard ? (
          <>
            <Box
              color="light"
              padding="sm"
            >
              <Row alignItems="center">
                <Col>
                  <Heading
                    level={3}
                    size={5}
                    noMargin
                  >
                    <Text color="primary">{jobTitle}</Text>
                  </Heading>
                </Col>
                <Col xs="auto">
                  <Badges noMargin>
                    {Math.random() > 0.5 && (
                      <Badge color="white">Yrittäjäpaikka</Badge>
                    )}
                    {Math.random() > 0.5 && (
                      <Badge color="white">Kokoaikainen</Badge>
                    )}
                  </Badges>
                </Col>
              </Row>
            </Box>
            <Box
              grow
              padding="sm"
              noMargin
            >
              <Row>
                <Col>
                  <Text
                    as="p"
                    lineHeight="sm"
                  >
                    {description}
                  </Text>
                </Col>
                <Col xs={4}>
                  <Text
                    align="right"
                    fontWeight="semi-bold"
                    lineHeight="sm"
                  >
                    {company}
                  </Text>
                  <Spacer marginTop="sm">
                    <Inline
                      alignItems="center"
                      gap="xs"
                      justifyContent="end"
                    >
                      <LocationIcon
                        block
                        size="lg"
                      />
                      {location}
                    </Inline>
                  </Spacer>
                  {Math.random() > 0.5 && (
                    <Spacer marginTop="sm">
                      <Inline
                        alignItems="center"
                        gap="xs"
                        justifyContent="end"
                      >
                        <AlertCircleIcon
                          block
                          color="danger"
                          size="lg"
                        />
                        <Text color="danger">Hakuaika umpeutumassa!</Text>
                      </Inline>
                    </Spacer>
                  )}
                </Col>
              </Row>
            </Box>
          </>
        ) : (
          <>
            <Box
              color="light"
              padding="sm"
            >
              <Heading
                align="center"
                level={3}
                size={5}
                noMargin
              >
                <Text color="primary">{jobTitle}</Text>
              </Heading>
            </Box>
            <Box
              grow
              padding="sm"
              noMargin
            >
              <Lead noMargin>{company}</Lead>
              <Spacer marginTop="sm">
                <Inline
                  alignItems="center"
                  gap="xs"
                >
                  <LocationIcon
                    block
                    size="lg"
                  />
                  {location}
                </Inline>
              </Spacer>
              <Spacer marginTop="sm">
                <Text
                  as="p"
                  lineHeight="sm"
                >
                  {description}
                </Text>
              </Spacer>
              {Math.random() > 0.5 && (
                <Spacer marginTop="sm">
                  <Badges noMargin>
                    <Badge>Kokoaikainen</Badge>
                  </Badges>
                </Spacer>
              )}
              {Math.random() > 0.5 && (
                <Spacer marginTop="sm">
                  <Inline
                    alignItems="center"
                    gap="xs"
                  >
                    <AlertCircleIcon
                      block
                      color="danger"
                      size="lg"
                    />
                    <Text color="danger">Hakuaika umpeutumassa!</Text>
                  </Inline>
                </Spacer>
              )}
            </Box>
          </>
        )}
      </BoxGroup>
      {/* EXAMPLE_END */}
    </>
  )
}

export { BoxJobExample }

import React from 'react'

import {
  Blockquote,
  Breadcrumb,
  BreadcrumbItem,
  Divider,
  Heading,
  Link,
  Main,
  Spacer,
  Wrapper
} from '@te-digi/styleguide'

import { FigureExample } from '../../../pages/verkkopalvelu/figure'
import { LinkMock } from '../../../components/LinkMock'
import { SkipLinkHTMLExample } from '../../../pages/navigointi/skiplink'
import { SomeButtonHTMLExample2 } from '../../../pages/navigointi/somebutton'

import { GalleryExample } from '../../../pages/kuvat-ja-videot/gallery'
import { HeaderHTMLExample } from '../HeaderHTMLExample'
import { FooterHTMLExample } from '../FooterHTMLExample'

const ArticleExample = () => (
  <>
    {SkipLinkHTMLExample()}
    <Wrapper>
      {HeaderHTMLExample({ activePrimaryItem: '' })}
      <div className="columns columns-single">
        <Main>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link as={LinkMock}>Etusivu</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link as={LinkMock}>Ajankohtaista</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>
              Työsuojelun vinkit - Tauotus ja jäähdytys auttavat jaksamaan
              helteellä
            </BreadcrumbItem>
          </Breadcrumb>
          <Heading noMargin>
            Työsuojelun vinkit - Tauotus ja jäähdytys auttavat jaksamaan
            helteellä
          </Heading>
          <Divider />
          <div className="flex align-items-center mb-lg">
            <div className="flex-fill">
              <time dateTime="2019-07-11">7.11.2019</time> -{' '}
              <LinkMock>Työmarkkinatori</LinkMock>
            </div>
            <span className="badge badge-secondary">Uutinen</span>
          </div>
          <div className="typography">
            <p className="lead">
              Koneellinen jäähdytys on helpottanut helteellä työskentelevien
              oloja monilla työpaikoilla. Kuumuus kiusaa kuitenkin edelleen
              monia työssä. Ilma tuntuu sitä kuumemmalta, mitä kosteampaa se on.
              Esimerkiksi kasvihuoneissa tai keittiöissä voi olla niin kuumaa ja
              kosteaa, että työolot voivat pahimmillaan olla työntekijöiden
              terveydelle haitalliset.
            </p>
            {FigureExample()}
            <p>
              Nam molestie nec tortor. Donec placerat leo sit amet velit.
              Vestibulum id justo ut vitae massa. Proin in dolor mauris
              consequat aliquam. Donec ipsum, vestibulum ullamcorper venenatis
              augue.
            </p>
            <Blockquote>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed
              posuere interdum sem.
            </Blockquote>
            <p>
              Donec ultricies nisl ut felis, suspendisse potenti. Lorem ipsum
              ligula ut hendrerit mollis, ipsum erat vehicula risus, eu suscipit
              sem libero nec erat. Aliquam erat volutpat. Sed congue augue vitae
              neque. Nulla consectetuer porttitor pede. Fusce purus morbi tortor
              magna condimentum vel, placerat id blandit sit amet tortor.
            </p>
            <Heading
              level={2}
              size={3}
            >
              Mauris sed libero
            </Heading>
            <p>
              Suspendisse facilisis nulla in lacinia laoreet, lorem velit
              accumsan velit vel mattis libero nisl et sem. Proin interdum
              maecenas massa turpis sagittis in, interdum non lobortis vitae
              massa. Quisque purus lectus, posuere eget imperdiet nec sodales id
              arcu. Vestibulum elit pede dictum eu, viverra non tincidunt eu
              ligula.
            </p>
            <ul>
              <li>
                Vivamus placerat lacus vel vehicula scelerisque, dui enim
                adipiscing lacus sit amet sagittis, libero enim vitae mi
              </li>
              <li>In neque magna posuere, euismod ac tincidunt tempor est</li>
              <li>Ut suscipit nisi eu purus</li>
            </ul>
            <p>
              Nam molestie nec tortor. Donec placerat leo sit amet velit.
              Vestibulum id justo ut vitae massa. Proin in dolor mauris
              consequat aliquam. Donec ipsum, vestibulum ullamcorper venenatis
              augue. Aliquam tempus nisi in auctor vulputate, erat felis
              pellentesque augue nec, pellentesque lectus justo nec erat.
              Aliquam et nisl. Quisque sit amet dolor in justo pretium
              condimentum.
            </p>
          </div>
          {GalleryExample()}
          <Spacer marginBottom="lg" />
          {SomeButtonHTMLExample2()}
        </Main>
      </div>
      <FooterHTMLExample />
    </Wrapper>
  </>
)

export { ArticleExample }
